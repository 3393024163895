import { p, div, ol, li } from "tags";
import { CheckboxField, fieldType } from "../form";
import h from "h";
import "./NgoAgreement.scss";

export default function NgoAgreement({ field }) {
  return div(".fieldgroup", [
    div(".ngo-agreement", [
      h("h3", ".ngo and .ong Eligibility Requirements"),
      p(
        "All organizations registering for .ngo|.ong domain names need to certify that they meet the NGO (non-governmental organization) Eligibility Requirements below. Please note: To activate the new domains, NGO organizations will also need to complete a post-registration validation process."
      ),
      ol([
        li(
          "Focused on acting in the public interest: Whether in support of education or health, the environment or human rights, members of the .ngo|.ong community work for the good of humankind and/or the preservation of the planet and do not promote discriminate or bigotry."
        ),
        li(
          "Non-profit making/Non-profit focused entities: While many NGOs and ONGs engage in commercial activities or generate revenue in support of their missions, members of the .ngo|.ong community do not recognize profits or retain earnings."
        ),
        li(
          "Limited government influence: Recognizing that many NGOs and ONGs have important interactions with government, not least for reasons of funding (which may include receipt of some government funding in support of their programs), members of the .ngo|.ong community decide their own policies, direct their own activities, and are independent of direct government or political control."
        ),
        li(
          "Independent actors: Members of the .ngo|.ong community should not be political parties nor should be a part of any government. Participation in the work of a NGO or ONG is voluntary."
        ),
        li(
          "Active organizations: Members of the .ngo|.ong community are actively pursuing their missions on a regular basis."
        ),
        li(
          "Structured: Members of the .ngo|.ong community, whether large or small, operate in a structured manner (e.g. under bylaws, codes of conduct, organizational standards, or other government structures.)"
        ),
        li(
          "Lawful: Members of the .ngo|.ong community act with integrity within the bounds of law."
        )
      ])
    ]),
    div(".row", [
      h(CheckboxField, {
        width: 12,
        label: [
          "I, the Registrant, understand and agree to the ",
          h(
            "a",
            {href: "https://pir.org/our-impact/ngo-ong-policy/", target: "_blank"},
            ".ngo and .ong Registration Policy"
          ),
          "."
        ],
        field
      })
    ]),
    p(
      ".row",
      'By checking "I Agree" you confirm that you have read, and agree to the .ngo and .ong Registrant Policy.'
    )
  ]);
}

NgoAgreement.propTypes = {
  field: fieldType.isRequired
};
