import { h } from "h";

import { AsideHelp } from "../../elements/aside_help";
import CartItems from "./CartItems";
import CartTotals from "./CartTotals";
import ClaimsPopup from "./ClaimsPopup";
import EmailOptionsPopup from "./EmailOptionsPopup";
import BundleNoticePopup from "./BundleNoticePopup";

function CartDebug({ data }) {
  if (data) {
    return h("div", { style: { clear: "both" } }, [
      h("hr"),
      h(
        "pre",
        { style: { fontFamily: "monaco", fontSize: 12 } },
        JSON.stringify(data, 0, 2)
      )
    ]);
  } else {
    return h("span");
  }
}

function ViewCart({ cart, loading, actions }) {
  const hasBundle = cart.items.some(item => !!item.bundle);

  return h(".results", [
    h("h1", "Shopping Cart"),
    h(".left", [
      h(CartItems, { cart, loading, actions }),
      h(CartTotals, { cart, actions })
    ]),
    h(".right", h(AsideHelp, { supportOpen: cart.supportOpen })),
    h(ClaimsPopup, {
      open: cart.claimsDomains.length > 0,
      domains: cart.claimsDomains,
      onClose: actions.closeClaimsPopup,
      onContinue: actions.closeClaimsPopup
    }),
    h(EmailOptionsPopup, {
      open: cart.emailComparisonIsOpen,
      onClose: actions.closeEmailComparison
    }),
    (hasBundle && h(BundleNoticePopup, {
      onClose: actions.closeBundlePopup,
      onRemoveBundles: actions.removeDomainBundles,
      open: cart.bundleModalIsOpen,
      optInByDefault: cart.bundleOptInByDefault,
      text: cart.checkoutNotice,
    })),
    h(CartDebug, {
      data: cart.exploded
    })
  ]);
}

export default ViewCart;
