import { h, fasIcon } from "h";
import ReactModal from "react-modal";

function BundleNoticePopup({ optInByDefault, onClose, onRemoveBundles, open, text }) {
  return h(
    ReactModal,
    {
      style: {
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }
      },
      isOpen: open,
      onRequestClose: onClose,
      appElement: document.getElementById("app"),
      className: "checkout-notice",
      shouldCloseOnOverlayClick: false
    },
    [
      h("button.btn-link.modal-close", { onClick: onClose }, fasIcon("times")),
      h(".modal-content", [
        h("h2.modal-title", "A domain bundle was added to your cart"),
        h("p", text),
      ]),
      h(".modal-footer", footerButtons(optInByDefault, onClose, onRemoveBundles))
    ]
  );
}

function footerButtons(optInByDefault, onClose, onRemoveBundles) {
  const buttons = [h("button.btn", { onClick: onClose }, "Ok")];

  if (!optInByDefault) {
    buttons.unshift(h(
      "button.btn-link",
      {
        onClick: () => {
          onRemoveBundles();
          onClose();
        },
      },
      "No thanks"
    ));
  }

  return buttons;
}

export default BundleNoticePopup;
