import { h } from "h";

import TldFormCira from "./TldFormCira";
import TldFormNgo from "./TldFormNgo";
import TldFormEu from "./TldFormEu";
import TldFormGeneric from "./TldFormGeneric";

export default function TldForm(props) {
  switch (props.stepName) {
    case "tld_ca":
      return h(TldFormCira, props);

    case "tld_ngo":
    case "tld_ong":
      return h(TldFormNgo, props);

    case "tld_eu":
      return h(TldFormEu, props);

    default:
      return h(TldFormGeneric, props);
  }
}
