import React from "react";
import PropTypes from "prop-types";
import R from "ramda";
import c from "classnames";
import { xhrPost } from "common/xhr";
import _set from "lodash/fp/set";

import { h, parseDollarAmount, gaTrackEvent, updateHeaderCartItemCount } from "h";
import initCart from "./initCart";
import ViewCart from "./view_cart";

class ViewCartApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: initCart(this.props.cart),
      supportOpen: this.props.supportOpen,
      instantSavingsPromo: this.props.instant_savings_promo,
      loading: {},
      strictValidation: false,
      claimsDomains: [],
      emailComparisonIsOpen: false,
      bundleModalIsOpen: !!props.checkout_notice,
      bundleOptInByDefault: props.bundle_opt_in_by_default,
      serverError: null,
      checkoutNotice: props.checkout_notice,
      actions: this.buildActions([
        "changeTerm",
        "changeWhoisPrivacy",
        "changeIsPrimary",
        "changeMailboxes",
        "changePersonalMailboxes",
        "changeAuthCode",
        "applyPromoCode",
        "removePromoCode",
        "removeCartItem",
        "closeClaimsPopup",
        "closeBundlePopup",
        "openEmailComparison",
        "closeEmailComparison",
        "checkout",
        "removeDomainBundles",
        "track",
        "changeServerError"
      ])
    };
  }

  static propTypes = {
    cart: PropTypes.object.isRequired,
    supportOpen: PropTypes.bool.isRequired
  };

  componentDidMount() {
    if (this.state.instantSavingsPromo) {
      this.track("showed_instant_savings_promo");
    }
  }

  render() {
    const {
      actions,
      bundleModalIsOpen,
      bundleOptInByDefault,
      cart,
      checkoutNotice,
      claimsDomains,
      emailComparisonIsOpen,
      supportOpen,
      loading,
      serverError,
      strictValidation,
      instantSavingsPromo
    } = this.state;
    const propsToMerge = {
      bundleModalIsOpen,
      bundleOptInByDefault,
      checkoutNotice,
      supportOpen,
      serverError,
      strictValidation,
      instantSavingsPromo,
      claimsDomains,
      emailComparisonIsOpen
    };

    return h(ViewCart, {
      cart: R.merge(cart, propsToMerge),
      loading: name => !!loading[name],
      actions
    });
  }

  closeBundlePopup() {
    this.setState({ bundleModalIsOpen: false });
  }

  removeDomainBundles() {
    this.state
      .cart
      .items
      .filter((item) => {
        return item
          .components
          .some(component => component.description === 'bundle')
      })
      .forEach(item => this.removeCartItem(item));
  }

  buildActions(names) {
    let actions = {};
    names.forEach(n => {
      actions[n] = (this[n] || this.undefinedAction(n)).bind(this);
    });
    return actions;
  }

  undefinedAction(name) {
    return function() {
      /* eslint-disable no-console */
      console.error("Undefined action ", name, arguments);
    };
  }

  optimisticUpdateItem(name, f) {
    const cart = this.state.cart;
    const newItems = cart.items.map(item => {
      if (item.name == name) {
        return f(item);
      } else {
        return item;
      }
    });
    this.setState({ cart: { ...cart, items: newItems } });
  }

  optimisticDeleteItem(name) {
    const cart = this.state.cart;
    const newItems = cart.items.filter(item => item.name != name);
    this.setState({ cart: { ...cart, items: newItems } });
  }

  changeWhoisPrivacy(item, value) {
    const name = item.name;
    const whois_privacy = value ? "yes" : "no";
    const params = {
      name,
      whois_privacy,
      full: true
    };
    this.optimisticUpdateItem(
      name,
      updateFirstComponent("whois_privacy", whois_privacy)
    );
    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });

    if (value) {
      this.track("turn_on_whois_privacy", name);
    } else {
      this.track("turn_off_whois_privacy", name);
    }
  }

  changeIsPrimary(item, value) {
    const name = item.name;
    const is_primary = value ? "yes" : "no";

    const params = {
      name,
      is_primary,
      full: true
    };
    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });

    if (value) {
      this.track("turn_on_is_primary", name);
    } else {
      this.track("turn_off_is_primary", name);
    }
  }

  setCart(data) {
    const cart = initCart(data);
    this.setState({
      cart: cart,
      serverError: null,
      claimsDomains: []
    });

    const count = cart.items.size;
    updateHeaderCartItemCount(count);
    document.title = `Shopping Cart (${count}) - Hover`;
  }

  changeTerm(item, term) {
    const name = item.name;
    const params = {
      name,
      term: term,
      full: true
    };

    // For subscriptions, `term` is actually the plan_id so it doesn't make sense to increment it.
    if (item.components[0].type != "subscription") {
      this.optimisticUpdateItem(name, updateFirstComponent("term", +term));
    }

    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });
    this.track("change_term", name, term);
  }

  changeMailboxes(item, component, numEmails) {
    const name = item.name;
    const mailboxType = component.mailbox_type;

    const ps = item.components
      .filter(R.propEq("id", "email"))
      .map(c => [c.mailbox_type, c.num_emails]);

    const origAddons = R.fromPairs(ps);
    const newAddons = R.merge(origAddons, { [mailboxType]: +numEmails });
    const params = {
      name,
      addons: newAddons,
      full: true
    };

    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });
    this.track(`change_mailboxes_${c.mailbox_type}`, name, numEmails);
  }

  changePersonalMailboxes(item, value) {
    const name = item.name;
    const [numEmails, mailboxType] = value.split(" ");
    const addons = R.merge(
      { regular: 0, mailplus: 0 },
      { [mailboxType]: numEmails }
    );
    const params = {
      name,
      addons,
      full: true
    };

    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });
    this.track(`change_mailboxes_surname_${mailboxType}`, name, numEmails);
  }

  changeAuthCode(item, auth_code) {
    const name = item.name;
    const params = {
      name,
      auth_code,
      full: true
    };

    xhrPost("/cart/update_item", params).then(response => {
      this.setCart(response);
    });

    this.track("set_auth_code", name);
  }

  removeCartItem(item) {
    const name = this.cartDisplayToName(item.name);
    this.optimisticDeleteItem(name);
    xhrPost("/cart/remove_cart", { name: name }).then(response => {
      if (response.cart.length == 0) {
        document.location.href = "/";
      } else {
        this.setCart(response);
      }
    });

    this.track("remove_cart_item", name);
  }

  cartDisplayToName(name) {
    if (name === "Account balance funding") {
      return "account_funding";
    } else {
      return name;
    }
  }

  applyPromoCode(code) {
    xhrPost("/cart/apply_coupon", { code }).then(response => {
      if (response.succeeded) {
        this.setCart(response);
        this.track("apply_promo_code", name, code);
      } else {
        this.setState({ serverError: response.error });
        this.track("fail_apply_promo_code", name, code);
      }
    });
  }

  removePromoCode(code) {
    xhrPost("/cart/remove_coupon").then(response => {
      this.setCart(response);
      this.track("remove_promo_code", name, code);
    });
  }

  checkout(e) {
    e.preventDefault();
    const cart = this.state.cart;
    const invalidIndex = cart.items.findIndex(missingAuthCode);

    if (invalidIndex !== -1) {
      e.preventDefault();

      const name = cart.items[invalidIndex].name;
      const box = $(`.left .items .box:eq(${invalidIndex})`);
      $("html, body").animate({ scrollTop: box.offset().top - 40 }, 500, function() {
        box.find(".error input").focus();
      });
      this.setState({ strictValidation: true });
      this.track("checkout_error", name);
    } else {
      xhrPost("/cart/checkout").then(response => {
        if (response.succeeded) {
          const cartTotal = parseDollarAmount(cart.total);
          this.track("checkout_success", name, cartTotal);
          document.location.href = response.url;
        } else if (response.error === "claim") {
          this.showClaimDialog(response.domains);
        }
      });
    }

    function missingAuthCode(item) {
      const c = item.components[0];
      return c.need_auth_code && (c.auth_code || "") == "";
    }
  }

  showClaimDialog(domains) {
    this.setState({ claimsDomains: domains });
  }

  closeClaimsPopup() {
    xhrPost("/cart/clear_claims").then(response => {
      const data = response.cart;
      if (data.cart.length == 0) {
        document.location.href = "/";
      } else {
        this.setCart(data);
      }
    });
  }

  openEmailComparison() {
    this.track("open_email_comparison");
    this.setState({ emailComparisonIsOpen: true });
  }

  closeEmailComparison() {
    this.track("close_email_comparison");
    this.setState({ emailComparisonIsOpen: false });
  }

  track(action, label, value) {
    gaTrackEvent("ViewCart", action, label, value);
  }

  changeServerError(value) {
    this.setState({ serverError: value });
  }
}

function updateFirstComponent(prop, val) {
  return item => _set(["components", 0, prop], val, item);
}

export default ViewCartApp;
