import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { div, button } from "tags";
import c from "classnames";
import { h, link, fasIcon } from "h";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  TextField,
  PasswordField,
  CheckboxField,
  fieldType,
} from "../form";
import MagicSigninLink from "./MagicSigninLink";

export class SigninForm extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      username: fieldType.isRequired,
      password: fieldType.isRequired,
      showPassword: fieldType.isRequired,
      remember: fieldType.isRequired,
    }).isRequired,
    signin: PropTypes.shape({
      note: PropTypes.string,
      loading: PropTypes.bool,
    }),
    actions: PropTypes.shape({
      signinRequest: PropTypes.func.isRequired,
      forgotUsername: PropTypes.func.isRequired,
      forgotPassword: PropTypes.func.isRequired,
    }).isRequired,
    cancelButton: PropTypes.node, // not required
  };

  constructor(props) {
    super(props);
  }

  handleSubmit(values) {
    const { actions } = this.props;
    const { username, password, remember } = values;
    const submitForm = (token) =>
      actions.signinRequest(username, password, remember, token).then((data) => {
        if (data.succeeded) {
          actions.successfulSignin(data);
          return Promise.resolve();
        } else {
          throw "not_successful";
        }
      });

    submitForm(null).catch(() => {});
  }

  signinRequest(username, password, remember, token) {

  }

  render() {
    const {
      form,
      fields,
      signin,
      cancelButton,
      actions: { forgotUsername, forgotPassword, goToStep },
    } = this.props;
    const { username, password, showPassword, remember } = fields;

    const loading = signin.isLoading;
    const hasError = signin.error;
    const className = c("account_signin_form", { loading, hasError });
    const note = signin.note;

    const forgotUsernameButton = button(
      ".btn-link.field-help",
      {
        type: "button",
        onClick: forgotUsername,
        tabIndex: -1,
      },
      "Forgot Username?"
    );

    const forgotPasswordButton = button(
      ".btn-link.field-help",
      {
        type: "button",
        onClick: forgotPassword,
        tabIndex: -1,
      },
      "Forgot Password?"
    );

    const onSubmit = (values) => this.handleSubmit(values);

    return h(Fragment, [
    h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, {
          text: signin.error,
          customizer: customError(forgotPassword),
        }),
        div(".row", [
          h(TextField, {
            width: 12,
            field: username,
            autoFocus: true,
            label: "Username",
            help: forgotUsernameButton,
          }),
        ]),
        div(".row", [
          h(PasswordField, {
            width: 12,
            field: password,
            show: showPassword,
            label: "Password",
            help: forgotPasswordButton,
          }),
        ]),
        note && div(".row.signin-note", note),
        div(".signin-controls", [
          h(CheckboxField, {
            field: remember,
            label: "Keep me signed in",
            style: { float: "left" },
          }),
          div(".buttons", [
            h(SubmitButton, { form, text: "Sign in" }),
            cancelButton,
          ]),
        ]),
      ]),

    ]),
    signin.magicSigninEnabled && h(MagicSigninLink, { step: signin.step, onClick: goToStep }),
  ]);
  }
}

const fields = ["username", "password", "showPassword", "remember"];

export function validate(values) {
  return validator(values, {
    username: { presence: true },
    password: { presence: true },
  });
}

export function mapStateToProps(state) {
  return {
    initialValues: {
      username: state.signin.username,
    },
  };
}

function customError(action) {
  return (text) => {
    if (text.includes("has been locked")) {
      return h("div", [
        "Your account is locked because of too many failed sign in attempts. ",
        "It can be unlocked by ",
        link("resetting the password", { onClick: action }),
        ". If you are unable to reset your password, contact Hover Support.",
      ]);
    }
  };
}

export default connect(mapStateToProps)(form(fields, validate)(SigninForm));
